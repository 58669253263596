<script setup lang="ts">
import MobileDetect from 'mobile-detect'

const url = ref('https://play.google.com/store/apps/details?id=com.x05ru.release&hl=ru&gl=US')

onMounted(() => {
  if (new MobileDetect(navigator.userAgent).os() === 'iOS' || new MobileDetect(navigator.userAgent).userAgent() === 'Safari')
    url.value = 'https://apps.apple.com/us/app/05-ru/id1539023015?l=ru/'
  else
    url.value = 'https://play.google.com/store/apps/details?id=com.x05ru.release&hl=ru&gl=US'
})
</script>

<template>
  <nuxt-link
    :to="url"
    target="_blank"
    class="header-bar link link_wu"
  >
    <div class="header-bar__logo">
      <img
        src="./asset/logo.svg"
        alt="Близко"
        class="header-bar__logo-image"
      >
    </div>
    <div class="header-bar__content">
      <NuxtImg
        src="/images/banner/cola.png"
        alt="Напитки"
        format="webp"
        class="header-bar__cola header-bar__image"
      />

      <NuxtImg
        src="/images/banner/pumpkin.png"
        alt="Овощи"
        format="webp"
        class="header-bar__pumpkin header-bar__image"
      />

      <NuxtImg
        src="/images/banner/milk.png"
        alt="Молочные продукты"
        format="webp"
        class="header-bar__milk header-bar__image"
      />

      <div class="header-bar__title">
        Заказывать <br class="header-bar__title-br"> удобнее в приложении
      </div>

      <NuxtImg
        src="/images/banner/cheese.png"
        alt="Cыр"
        format="webp"
        class="header-bar__cheese header-bar__image"
      />

      <NuxtImg
        src="/images/banner/watermelon.png"
        alt="Арбуз"
        format="webp"
        class="header-bar__watermelon header-bar__image"
      />

      <NuxtImg
        src="/images/banner/grapes.png"
        alt="Ягоды"
        format="webp"
        class="header-bar__grapes header-bar__image"
      />

      <NuxtImg
        src="/images/banner/cola-x-mobile.png"
        alt="Кола перевернутая"
        format="webp"
        class="header-bar__cola-x-mobile header-bar__image"
      />

      <NuxtImg
        src="/images/banner/cola-mobile.png"
        alt="Кола"
        format="webp"
        class="header-bar__cola-mobile header-bar__image"
      />

      <NuxtImg
        src="/images/banner/watermelon-mobile.png"
        alt="Арбуз"
        format="webp"
        class="header-bar__watermelon-mobile header-bar__image"
      />
    </div>

    <button class="header-bar__button">
      <span class="header-bar__button-full-text">Скачайте на iOS или Android</span>
      <span class="header-bar__button-short-text">Скачать</span>
    </button>
  </nuxt-link>
</template>

<style scoped lang="postcss">
@font-face {
  font-family: 'RightGrotesk';
  font-style: normal;
  font-weight: 500;
  src: url('/vue/font/rightgrotesk-medium.woff2') format('woff2'), url('/vue/font/rightgrotesk-medium.woff') format('woff');
  font-display: swap;
}

.header-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
  padding: 0 70px;
  font-family: 'RightGrotesk', sans-serif;
  cursor: pointer;
  background: #F5EE3A;

  @media (max-width: 800px) {
    height: 40px;
  }

  &__button {
    padding: 10px 16px;
    font-family: 'RightGrotesk', sans-serif;
    font-size: 16px;
    line-height: 16px;
    color: #fff;
    text-wrap: nowrap;
    cursor: pointer;
    background-color: #00B0B8;
    border: none;
    border-radius: 12px;

    &-short-text {
      display: none;
    }
  }

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc(100% - 385px);
    max-width: 1920px;
    height: 100%;
  }

  &__image {
    position: absolute;
  }

  &__cola {
    left: 30px;
    width: 177px;

    &-mobile {
      display: none;
    }

    &-x-mobile {
      display: none;
    }
  }

  &__milk {
    left: 287px;
    width: 181px;
    height: 50px;
  }

  &__pumpkin {
    left: 160px;
    width: 164px;
    height: 50px;
  }

  &__grapes {
    right: 13px;
    width: 154px;
    height: 50px;
  }

  &__watermelon {
    right: 122px;
    width: 206px;
    height: 50px;

    &-mobile {
      display: none;
    }
  }

  &__cheese {
    right: 303px;
    width: 160px;
    height: 50px;
  }

  &__title {
    z-index: 5;
    display: flex;
    align-items: center;
    padding-right: 0px;
    font-family: 'RightGrotesk', sans-serif;
    font-size: 25px;
    font-weight: 600;
    line-height: 28px;
    color: #15252b;
    letter-spacing: -1.4px;

    &-br {
      display: none;
    }
  }

  @media (max-width: 1683px) {
    padding: 0 50px;

    &__title {
      padding-right: 50px;
    }

    &__cola {
      left: -40px;
    }

    &__pumpkin {
      left: 70px;
    }

    &__milk {
      left: 184px;
    }

    &__grapes {
      right: -7px;
      width: 165px;
    }

    &__watermelon {
      right: 95px;
    }

    &__cheese {
      right: 260px;
    }
  }

  @media (max-width: 1601px) {
    padding: 0 40px;

    &__title {
      padding-right: 30px;
    }

    &__milk {
      display: none;
    }

    &__cheese {
      display: none;
    }

    &__grapes {
      right: -5px;
    }

    &__watermelon {
      right: 118px;
    }

    &__cola {
      left: 0px;
    }

    &__pumpkin {
      left: 145px;
    }

  }

  @media (max-width: 1430px) {
    padding: 0 30px;

    &__title {
      padding-right: 80px;
    }

    &__milk {
      display: none;
    }

    &__cheese {
      display: none;
    }

    &__grapes {
      right: 10px;
    }

    &__watermelon {
      right: 110px;
    }

    &__cola {
      left: -28px;
    }

    &__pumpkin {
      left: 90px;
    }
  }

  @media (max-width: 1301px) {

    &__title {
      padding-right: 20px;
    }

    &__grapes {
      display: none;
    }

    &__pumpkin {
      display: none;
    }

    &__watermelon {
      right: -10px;
    }

    &__cola {
      left: -5px;
    }

  }

  @media (max-width: 1180px) {
    padding: 0 20px;

    &__title {
      padding-right: 55px;
    }

    &__cola {
      left: -25px;
    }
  }

  @media (max-width: 1001px) {

    &__title {
      padding-right: 0px;
      padding-left: 97px;
    }

    &__cola {
      left: -38px;
    }

    &__watermelon {
      display: none;
    }
  }

  @media (max-width: 800px) {

    &__logo {
      display: none;
    }

    &__content {
      justify-content: flex-start;
      width: calc(100% - 110px)
    }

    &__button {
      width: 110px;
      height: 30px;
      padding: 6px 20px;

      &-short-text {
        display: block;
      }

      &-full-text {
        display: none;
      }
    }

    &__cola {
      display: none;

      &-mobile {
        bottom: 0;
        left: 59%;
        display: block;
        width: 111px;
      }
    }

    &__title {
      max-width: 185px;
      padding-left: 0;
      font-size: 16px;
      font-weight: 600;
      line-height: 17px;

      &-br {
        display: block;
      }
    }
  }

  @media (max-width: 429px) {

    &__cola-mobile {
      left: 63%;
    }

  }

  @media (max-width: 320px) {
    padding: 0 4px 0 7px;

    &__cola-mobile {
      display: none;
    }

    &__cola-x-mobile {
      top: 0;
      left: 40%;
      display: block;
      width: 65px;
    }

    &__watermelon-mobile {
      top: -80%;
      left: 70%;
      display: block;
      width: 64px;
      height: 64px;
    }
  }

}
</style>
